// Colors Variables
$black      : #222222;
$blue-dark  : #032E61;
$blue       : #215CA0;
$blue-light : #00A1E0;
$cyan-dark  : #8CD3F8;
$cyan       : #C2E8FF;
$cyan-light : #E3F7FF;
$white      : white;
$green      : #00B2A9;
$yellow     : #EBBC48;
$red        : #FD7456;
$purple     : #7D52C2;
$brown      : #784834;
$grey       : #BEBEBE;
$grey-light : #F7F7F7;
$grey-dark  : #7E7E7E;

// Fonts
$font-script    : 'Trailhead','Georgia',serif;
$font-header    : 'AvantGarde','Helvetica Neue','Helvetica','Arial',sans-serif;
$font-sans      : 'SalesforceSans','Helvetica Neue','Helvetica','Arial',sans-serif;