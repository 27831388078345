/*********************
BUTTONS
*********************/

.button{
    font-family: $font-sans;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 4px;
    padding: 0.875em 2.25em;
    outline: none!important;
    font-size: 1em;
    text-decoration: none;
    &:focus{
        outline: none;
    }
    &.wide{
        min-width: 250px;
        width: 100%;
        @include breakpoint(medium){
            width: auto;
        }
    }
    &.full{
        width: 100%;
    }
    &.btn-outline-blue-dark{
        border: 2px solid $blue-dark;
        background: $white;
        color: $blue-dark;
        &:hover{
            background: $blue-dark;
            color: $white;
        }
    }
    &.btn-red{
        background: $red;
        &:hover{
            background: darken($red, 10%);
        }
    }
    &.btn-yellow{
        background: $yellow;
        &:hover{
            background: darken($yellow, 10%);
        }
    }
    &.btn-blue{
        background: $blue-light;
        &:hover{
            background: darken($blue-light, 10%);
        }
    }
}
