@import '../../styles/variables';
@import '../../styles/mixin';
.cert-list {
    list-style-type: none;
    font-size: 1.2em;
}
#testimonial {
    background-color: rgb(211, 239, 255);
    .card__body {
        min-height: 60px;
    }
}
.spacer {
    height: 550px;
    @include respond-to('large') {
       height: 350px;}
    
}
.bottom-message {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: (translateX(-50%));
}
.photo-wrap {
    img {
        height: 125px;
    }
}
.footer-info-bottom {
    margin-top: 50px;
    font-size: 0.68em;
    position: absolute;
    bottom: 20px;
    color: #fff !important;
    a {
        text-decoration: underline;
        color: #ddd !important;
    }
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 43, 0, 0.85);
    padding: 15px;
}
.bottom-disclaimer {
    font-size: 0.7em;
}

.page-footer_links_list {
    list-style-type: none;
    .page-footer_links_item {
        font-size: inherit;
        display: inline-block;
        margin: 5px;
    }
}
