/*********************
UTILS
*********************/

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space * .5}px !important;
            @include breakpoint(medium){
                margin-#{$side}: #{$space * .75}px !important;
            }
            @include breakpoint(large){
                margin-#{$side}: #{$space}px !important;
            }
        }

        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space * .5}px !important;
            @include breakpoint(medium){
                padding-#{$side}: #{$space * .75}px !important;
            }
            @include breakpoint(large){
                padding-#{$side}: #{$space}px !important;
            }
        }
    }
}

.relative{
    position: relative;
}

.overflow-hidden{
    overflow: hidden;
}

.fixed{
    position: fixed;
}

.full-height{
    min-height: 100vh;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    @include breakpoint(medium){
        padding-top: 20px;
    }
}
.height80{
    min-height: 80vh;
    height: 80%;
}


.block{
    display: block;
}

.flex{
    display: flex;
}

.baseline{
    align-items: baseline;
}
