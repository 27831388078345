.cert-list{
    list-style-type: none;
    font-size: 1.2em;
}
#eligibility{
    background-color: #e5f6fa;
        .card__body{
    min-height: 60px;
}
.disclaimer-title{
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;
}
    .disclaimer{
        padding-top:0;
        margin-top:0;
        opacity: 0.8;
        font-size: 0.8em;
        margin-left: 10px;
        margin-right: 10px;
    }
}
.eligibility-card{
    display: block;
}