@import '../../styles/variables';
@import '../../styles/mixin';
#hero{
  background-image: url('/images/hero_4.png');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  h1{
      font-size: 2em;
    //   margin-bottom: 20px;
  }
  .logo{
      width:150px;
      margin-bottom: 20px;
  }
  .capitalize{text-transform: capitalize!important;}
  .hero-heading{
      margin-bottom: 200px;
      margin-top: 100px;
      @include respond-to('medium') {
          margin-bottom: 50px;
      }

      @include respond-to('large') {
          margin-bottom: 50px;
      }
      @include respond-to('xxlarge') {
          margin-bottom: 120px;
          margin-top: 150px;

      }
      }
      .footnote{
        opacity: 0.7;
        font-size: 0.8em;
    }
}
.card-country{
    overflow: hidden;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 45.25%!important;
    background-color: #00a1e0;
    background-position: top;
}
.country-grid{
    max-width: 500px;
    margin: 0 auto;
}
.card-australia{
    background-image: url("images/australia.png");
}
.card-new-zealand{
    background-image: url("images/new_zealand.png");
}
.card-south-africa{
    background-image: url("images/south_africa.png");
}
.card-united-states{
    background-image: url("images/united_states.png");
}
// .hero-heading{

// }