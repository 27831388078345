
/*********************
RESOURCES
*********************/
#resources, #contact{
    position: relative;
    overflow: hidden;
    @include respond-to('medium') {
        padding-top: 2em;
    }
    &:before{
        content: '';
        position: absolute;
        display: block;
        background-color: $white;
        width: 100%;
        height: 400px;
        top: 0;
        left: 0;
    }
    .cloud4{
        top: 400px;
    }
}

.bird5{
    .inner{
      transform: scaleX(-1);
    }
    position: absolute;
    width: 150px;
    height: 150px;
    left: 5%;
    top: -50px;
    @include respond-to('medium') {
        top: 10%;
        right: 10%;
    }
    @include respond-to('large') {
        right: 20%;
    }
}
.bird4{
    // background-repeat: no-repeat;
    position: absolute;
    //background-image: url(../img/SF-STARs-myTrailhead-Hummingbird-hover.svg);
    width: 150px;
    height: 150px;
    right: 0;
    top: 20%;
    @include respond-to('medium') {
        top: 20%;
        right: 10%;
    }
    @include respond-to('large') {
        right: 20%;
    }
}
