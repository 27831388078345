/*********************
PAGINATION
*********************/

.pagination{
    font-family: $font-sans;
    background: $blue-light;
    display: inline-block;
    color: $white;
    padding: .5rem 1rem;
    border-radius: 50px;
    font-size: .9rem;
}
