@import '../../styles/variables';
@import '../../styles/mixin';
.bird4{
    background-repeat: no-repeat;
    position: absolute;
    //background-image: url(../img/SF-STARs-myTrailhead-Hummingbird-hover.svg);
    width: 150px;
    height: 150px;
    right: 0;
    top: 0;
    @include respond-to('medium') {
        top: 10%;
        right: 10%;
    }
    @include respond-to('large') {
        right: 20%;
    }
}
#awards{
    position: relative;
    .card__body{
        min-height: 60px;
    }
}

.card__inner{
    padding: 20px;
}
.pad-top-50{
    padding-top:50px;
}
