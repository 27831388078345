/*********************
TYPOGRAPHY
*********************/
body{
    font-size: 90%;
    @include breakpoint(medium){
        font-size: 100%;
    }
}

.bold{
    font-weight: bold;
}

.italic{
    font-style: italic;
}

.underline{
    text-decoration: underline;
}

.uppercase{
    text-transform: uppercase;
}

.lowercase{
    text-transform: lowercase;
}

.script{
    font-family: $font-script;
}

.small{
    font-size: .75rem;
}

p{
    font-size: 1.125em;
}
.medium{
    //font-size: 1em;
}
