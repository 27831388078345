@import "./styles/fonts";
@import "./styles/variables";
@import "./styles/mixin";
@import "./styles/colours";
@import "./styles/foundation";
@import "./styles/global";

// .slide{
//   min-height: 100vh;
// }
// body{overflow-y: scroll;}
.animate-opacity {
  transition: all 1s;
}
.invisible {
  opacity: 0;
}
.hidden {
  display: none;
}
.display-flex {
  display: flex;
}
.name-script {
  margin-right: 5px;
  text-transform: capitalize;
}
.capitalize {
  text-transform: capitalize;
}
.padding-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pad-bottom-100 {
  padding-bottom: 100px;
}
.pad-top-100 {
  padding-top: 100px;
}
.pad-top-20 {
  padding-top: 20px;
}
.pad-20 {
  padding: 20px;
}
.pad-bottom-50 {
  padding-bottom: 50px;
}
.pad-top-50 {
  padding-top: 50px;
}
.mar-bot-20 {
  margin-bottom: 20px;
}
.round {
  border-radius: 50%;
}
//# ||****\ MODAL
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow-y: hidden;
}
.home-modal {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.video-modal {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;

  @include respond-to("medium") {
    width: 80%;
  }
}

.form-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-top: 20px;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(3, 46, 97, 0.95);
  z-index: 999;
}

.my-close-button {
  right: 0rem;
  top: 0rem;
  font-size: 2em;
  line-height: 0;
  background: #fff;
  width: 40px;
  height: 40px;
}
.video-close {
  text-decoration: none;
  @include respond-to("medium") {
    top: -45px;
    right: -40px;
    width: 40px;
    font-size: 51px;
    color: #fff;
  }
}
//# ||****//////////
.top-logo {
  width: 100px;
  margin-bottom: 20px;
}
.burger-cell {
  padding-right: 5px !important;
}
.burger-button {
  float: right;
}
.logo-header {
  img {
    width: 60px;
    padding: 5px;
    @include respond-to("medium") {
      width: 80px;
    }
  }
}
.mw-700{
  max-width: 800px;
}
@media screen and (max-height: 700px) {
  .logo-header {
    img {
      width: 40px;
      padding: 0px;
    }
  } 
}
.main-menu {
  .menu {
    li {
      .active {
        text-decoration: underline;
      }
    }
  }
}
.main-title {
  font-size: 3em;
  @include respond-to("large") {
    font-size: 3em !important;
  }
  @include respond-to("xxxlarge") {
    font-size: 5em !important;
  }
}

.intro-title {
  font-size: 1em;
  @include respond-to("large") {
    font-size: 1.3em !important;
  }
}
.main-sub-title {
  @include respond-to("large") {
    font-size: 1.3em !important;
  }
}
.no-underline {
  text-decoration: none !important;
}
