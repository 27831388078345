// Color map
$colors: (
    black       : $black,
    blue-dark   : $blue-dark,
    blue        : $blue,
    blue-light  : $blue-light,
    cyan-dark   : $cyan-dark,
    cyan        : $cyan,
    cyan-light  : $cyan-light,
    white       : $white,
    green       : $green,
    yellow      : $yellow,
    red         : $red,
    brown       : $brown,
    purple      : $purple,
    grey        : $grey,
    grey-light  : $grey-light,
    grey-dark   : $grey-dark
);

// Loop to spit out color for fonts and colors for backgrounds
@each $name,
$color in $colors {
    .#{$name} {
        color: $color;
    }

    .bg-#{$name} {
        background-color: $color;
    }
}
