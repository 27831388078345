//Fonts

@font-face {
    font-family: 'SalesforceSans';
    src: url('/fonts/SalesforceSans/SalesforceSans-Bold.eot');
    src: url('/fonts/SalesforceSans/SalesforceSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/SalesforceSans/SalesforceSans-Bold.woff2') format('woff2'),
        url('/fonts/SalesforceSans/SalesforceSans-Bold.woff') format('woff'),
        url('/fonts/SalesforceSans/SalesforceSans-Bold.ttf') format('truetype'),
        url('/fonts/SalesforceSans/SalesforceSans-Bold.svg#SalesforceSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SalesforceSans';
    src: url('/fonts/SalesforceSans/SalesforceSans-Regular.eot');
    src: url('/fonts/SalesforceSans/SalesforceSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/SalesforceSans/SalesforceSans-Regular.woff2') format('woff2'),
        url('/fonts/SalesforceSans/SalesforceSans-Regular.woff') format('woff'),
        url('/fonts/SalesforceSans/SalesforceSans-Regular.ttf') format('truetype'),
        url('/fonts/SalesforceSans/SalesforceSans-Regular.svg#SalesforceSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SalesforceSans';
    src: url('/fonts/SalesforceSans/SalesforceSans-Light.eot');
    src: url('/fonts/SalesforceSans/SalesforceSans-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/SalesforceSans/SalesforceSans-Light.woff2') format('woff2'),
        url('/fonts/SalesforceSans/SalesforceSans-Light.woff') format('woff'),
        url('/fonts/SalesforceSans/SalesforceSans-Light.ttf') format('truetype'),
        url('/fonts/SalesforceSans/SalesforceSans-Light.svg#SalesforceSans-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'AvantGarde';
    src: url('/fonts/AvantGarde/AvantGarde-Bold.eot');
    src: url('/fonts/AvantGarde/AvantGarde-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/AvantGarde/AvantGarde-Bold.woff2') format('woff2'),
        url('/fonts/AvantGarde/AvantGarde-Bold.woff') format('woff'),
        url('/fonts/AvantGarde/AvantGarde-Bold.ttf') format('truetype'),
        url('/fonts/AvantGarde/AvantGarde-Bold.svg#AvantGarde-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AvantGarde';
    src: url('/fonts/AvantGarde/AvantGarde-Medium.eot');
    src: url('/fonts/AvantGarde/AvantGarde-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/AvantGarde/AvantGarde-Medium.woff2') format('woff2'),
        url('/fonts/AvantGarde/AvantGarde-Medium.woff') format('woff'),
        url('/fonts/AvantGarde/AvantGarde-Medium.ttf') format('truetype'),
        url('/fonts/AvantGarde/AvantGarde-Medium.svg#AvantGarde-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Trailhead';
    src:  url('/fonts/Trailhead/TrailheadMedium.eot');
    src:  url('/fonts/Trailhead/TrailheadMedium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Trailhead/TrailheadMedium.ttf') format('truetype'),
      url('/fonts/Trailhead/TrailheadMedium.woff') format('woff'),
      url('/fonts/Trailhead/TrailheadMedium.svg#TrailheadMedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Trailhead';
    src:  url('/fonts/Trailhead/TrailheadBold.eot');
    src:  url('/fonts/Trailhead/TrailheadBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Trailhead/TrailheadBold.ttf') format('truetype'),
      url('/fonts/Trailhead/TrailheadBold.woff') format('woff'),
      url('/fonts/Trailhead/TrailheadBold.svg#TrailheadBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}
