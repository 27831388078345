// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) { 
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

//Transition
@mixin transition($args) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Animation
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
}

@mixin animation-delay($str) {
    -webkit-animation-delay: $str;
    animation-delay: $str;
}

@mixin text-outline($color_outline, $color_fill, $thickness){
    color: $color_outline;
    -webkit-text-fill-color: $color_fill; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: $thickness;
    -webkit-text-stroke-color: $color_outline;
}

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints

$breakpoints_map: (
  'small'   : ( min-width:  0px ),
  'medium'  : ( min-width:  640px ),
  'large'   : ( min-width: 1024px ),
  'xlarge'  : ( min-width: 1200px ),
  'xxlarge' : ( min-width: 1440px ),
  'xxxlarge' : ( min-width: 1920px )
) !default;

@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints_map, $breakpoint) {
      // Prints a media query based on the value
      @media #{inspect(map-get($breakpoints_map, $breakpoint))} {
        @content;
      }
    }
   
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints_map)}.";
    }
  }