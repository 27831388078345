// .butterfly-group{
//     transform: translateY(400px);
// }
.disclaimer{
    position: absolute;
    z-index: 99;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.6em;
}