/*********************
FOOTER
*********************/

.cloud4, .cloud5, .cloud6{
    background-repeat: no-repeat;
    position: absolute;
    background-size: contain;
    transform: scaleX(-1);
}

.cloud4{
    background-image: url(/images/cloud4.png);
    width: 1920px;
    height: 331px;
    background-size: 100%;
    background-position: center top;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    @include breakpoint(xlarge){
        width: 100%;
        background-repeat: repeat-x;
        background-size: auto;
    }
}

.cloud5{
    background-image: url(/images/cloud1.png);
    width: 550px;
    height: 144px;
    left: 0;
    top: 60%;
    transform: translate(0, -50%);

}

.cloud6{
    background-image: url(/images/cloud2.png);
    width: 590px;
    height: 62px;
    right: 0;
    top: 80%;
    transform: translateY(-50%);
}

.bg-footer{
    background-image: url(/images/bg-footer.png);
    background-size: cover;
    background-repeat: repeat-x;
    background-position: 0 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 325px;
    width: 100%;
}

.butterfly1, .butterfly2{
    // background-repeat: no-repeat;
    position: absolute;
    z-index: 999;
    // background-size: contain;
    // background-position: center;
}

.butterfly1{
    //background-image: url(../img/SF-STARs-yTrailhead-Butterfly1-hover.svg);
    right: 10%;
    bottom: 0px;
    width: 100px;
    height: 100px;
    @include breakpoint(medium){
        bottom: 200px;
    }
}

.butterfly2{
    //background-image: url(../img/SF-STARs-yTrailhead-Butterfly2-hover.svg);
    left: 12%;
    bottom: 100px;
    width: 80px;
    height: 80px;
    @include breakpoint(medium){
        bottom: 150px;
    }
}
