.cert-list{
    list-style-type: none;
    font-size: 1.2em;
}
#offerings{
    // background-color: #e5f6fa;
        .card__body{
    min-height: 60px;
}

}
.card__links{
    padding-top:10px;
    span{
        padding: 5px;
    }
}