/*********************
FORM
*********************/

.my-form, form{
    &.large-script{
        text-align: center;
        label{
            font-family: $font-sans;
            color: $blue-dark;
            font-weight: bold;
        }
        [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea{
            font-family: $font-script;
            font-size: rem-calc(44);
            height: rem-calc(50);
            color: $blue-light!important;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $blue-dark;
            box-shadow: none;
            text-align: center;
            &:focus,
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus{
                background-color: transparent;
                box-shadow: none;
                border: none;
                border-bottom: 1px solid $blue-dark;
            }
        }
    }
    label{
        font-weight: 600;
        font-size: .9rem;
        &.normal{
            font-weight: 400;
        }
    }
}

input[type=hidden]{
    display: none;
}

.contact__form{
    border-radius: 25px;
    border: 1px solid $blue-dark;
    box-shadow: 0 15px 15px rgba($black, .2);
    background: $grey-light;
    padding: 3em 2em;
    text-align: center;
    .button{
        margin-bottom: 0;
    }
}
